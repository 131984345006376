<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="onQueryChange"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="onQueryChange('search')"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!fetching">
              <div class="table-responsive mb-0">
                <b-table
                  :items="businesses"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="onQueryChange"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(msg_limit)="data">
                    <span 
                      v-if="data.item.no_messages !== null"
                      class="font-weight-bold"
                      :class="{ 
                        'text-danger': data.item.usage_messages >= data.item.no_messages,
                        'text-warning': data.item.no_messages > data.item.usage_messages && data.item.usage_messages >= data.item.no_messages * .9,
                        'text-success': data.item.usage_messages * .9 > data.item.usage_messages,
                      }"
                    >{{data.item.usage_messages}} / {{data.item.no_messages}}</span>
                    <span v-else class="text-success font-weight-bold">Unlimited</span>
                  </template>
                  <template v-slot:cell(contact_limit)="data">
                    <span 
                      v-if="data.item.no_contacts !== null"
                      class="font-weight-bold"
                      :class="{ 
                        'text-danger': data.item.usage_contacts >= data.item.no_contacts,
                        'text-warning': data.item.no_contacts > data.item.usage_contacts && data.item.usage_contacts >= data.item.no_contacts * .9,
                        'text-success': data.item.usage_contacts * .9 > data.item.usage_contacts,
                      }"
                    >{{data.item.usage_contacts}} / {{data.item.no_contacts}}</span>
                    <span v-else class="text-success font-weight-bold">Unlimited</span>
                  </template>

                  <template v-slot:cell(completed)="data">
                    <feather
                      v-if="data.item.completed"
                      type="check"
                      class="icon-dual-success"
                    />
                  </template>

                  
                  <template v-slot:cell(reset_date)="data">
                    {{
                      data.item.reset_date
                        | moment(
                          `MM-DD`
                        )
                    }}
                  </template>
                  <template v-slot:cell(usage_cost_month)="data">
                    <span class="text-align-center">$ {{ data.item.usage_cost_month.toFixed(3) }}</span>
                  </template>

                  <template v-slot:cell(active)="data">
                    <b-form-checkbox
                      v-model="data.item.active"
                      switch
                      @change="handleChangeActive(data.item.id)"
                    ></b-form-checkbox>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <b-button
                      variant="primary"
                      size="sm"
                      title="Sign In"
                      class="ml-1"
                      :disabled="loadingImpersonate"
                      @click="impersonate(data.item.owner.id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                    <router-link
                      :to="{
                        name: 'admin.businesses.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                      variant="danger"
                      size="sm"
                      title="Delete"
                      class="ml-1"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                    <b-button
                      v-if="data.item.phone_number"
                      variant="danger"
                      size="sm"
                      title="Reset Number"
                      class="ml-1"
                      :disabled="loadingRemove === data.item.id"
                      @click="resetNumber(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-refresh"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalBusinesses }} businesses
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalBusinesses"
                        :per-page="datatable.queries.per_page"
                        @change="onQueryChange"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete Business">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business <b>"{{
          selectedBusiness.name
        }}"</b>? <br/>The business will be archived for 15 days and then permanently deleted.</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    
    <b-modal v-model="showResetNumberModal" title="Reset Business Number">
      <p v-if="selectedBusiness"
        >Are you sure you want to reset number of business <b>"{{
          selectedBusiness.name
        }}"</b>?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showResetNumberModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleResetNumber">Reset</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { key: 'owner.email', label: 'Email', sortable: true },
          { key: 'completed' },
          { key: 'phone_number', label: 'Number', sortable: false },
          { key: 'reset_date', label: 'Reset At'},
          { key: 'msg_limit', label: 'Messages', sortable: false },
          { key: 'contact_limit', label: 'Contacts', sortable: false },
          { key: 'usage_cost_month', label: 'Cost', sortable: true },
          { key: 'active' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingImpersonate: false,
      loadingRemove: null,
      showResetNumberModal: false,
    }
  },

  computed: {
    ...mapState('business', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      queries: 'queries',
      businesses: 'businesses',
      totalBusinesses: 'total',
    }),
  },

  created() {
  },

  mounted() {
    this.$store.dispatch('business/resetAll')
      .then(() => {
        this.onQueryChange();
      })
  },

  methods: {
    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search')
        {
          this.datatable.queries.page = 1;
        }
        this.$store.commit('business/updateState', {
          queries: {
            per_page: this.datatable.queries.per_page,
            page: this.datatable.queries.page,
            status: this.datatable.queries.status,
            search: this.datatable.queries.search,
            sort_by: this.datatable.queries.sort_by,
            sort_desc: this.datatable.queries.sort_desc,
          }
        })
        this.getBusinesses()
      });
    },

    getBusinesses() {
      this.$store
        .dispatch('business/getAll')
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    resetNumber(business) {
      this.showResetNumberModal = true
      this.selectedBusiness = business
    },

    handleResetNumber() {
      this.showResetNumberModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/resetNumber', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleChangeActive(business) {
      this.$store
        .dispatch('business/toggleActive', business)
        .then(() => {
        })
        .catch(() => {
        })
    },
  },
}
</script>